import React, { useState, useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Dialog,
} from '@material-ui/core';

import { MyCardRow } from 'components/Elements/MyCard';
import MyInput from 'components/Elements/MyInput';
import MyButton from 'components/Elements/MyButton';
import MyCreditCard from 'components/Elements/MyCreditCard';

import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';

import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { notNull, cleanPhone } from 'utils/text';
import { reservationService, isLoggedIn, getLoggedInUser, setLoggedInUser, logout } from 'services/reservationService';
import { paymentService } from 'services/paymentService';

import { DialogTitleContainer, DialogContentContainer, DialogActionsContainer } from './style';

import 'react-credit-cards/es/styles-compiled.css';

const AddCardModal = ({ modalOpen, handleModalClose }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [formErrors, setFormErrors] = useState(null);
  const [resultError, setResultError] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  const handleValueChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const checkValueValidate = (id, value) => {
    const valid = notNull(value) && value.toString().length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    }

    if (id === 'name' || id === 'number' || id === 'expiry' || id === 'cvc') {
      error = cardValid ? null : 'Please input valid card information';
    }

    return error;
  };

  const checkValidate = () => {
    const keys = ['name', 'number', 'expiry', 'cvc'];
    const errors = {};
    keys.forEach(item => {
      const error = checkValueValidate(item, notNull(formValues) ? formValues[item] : null);
      errors[item] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleAddPayment = async () => {
    if (!checkValidate()) {
      return;
    }

    showCircularProgress('Please wait...');

    const data = await paymentService.add_payment_to_passenger({
      ...formValues,
      number: formValues.number.replace(/\s/g, ''),
      expMonth: formValues.expiry.split('/')[0],
      expYear: formValues.expiry.split('/')[1],
      phone: cleanPhone(getLoggedInUser().phone1),
      token: getLoggedInUser().login_token
    });

    if (data && data?.data?.status === 'SUCCESS') {
      handleModalClose(true);
      const data1 = await reservationService.login_token_check();
      if (data1 && data1?.data?.status === 'FAIL') {
        logout();
      } else {
        setLoggedInUser({ ...getLoggedInUser(), ...data1.data.data });
      }
    } else {
      setResultError(`Failed to add card on file: ${data.data.data?.msg}`);
    }

    hideCircularProgress();
  }

  const handleCardCallback = ({ issuer }, isValid) => {
    handleValueChange('cardType', issuer);
    setCardValid(isValid);
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={modalOpen && isLoggedIn()}
      onClose={handleModalClose}
      closeable
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer>
        <CreditCardTwoToneIcon fontSize="small" /> <span>Card On File</span>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper card-dialog--wrapper border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column w-100">
              <MyCardRow>
                <MyCardRow direction="column" style={{ margin: 'auto' }}>
                  <MyCreditCard
                    cvc={formValues.cvc}
                    expiry={formValues.expiry}
                    focused={formValues.focus}
                    name={formValues.name}
                    number={formValues.number}
                    callback={handleCardCallback}
                  />
                  {resultError && (
                    <MyCardRow>
                      <p style={{ color: 'red', fontSize: 14, marginTop: 10, textAlign: 'center' }}>{resultError}</p>
                    </MyCardRow>
                  )}
                </MyCardRow>
                <MyCardRow direction="column">
                  <MyInput
                    label="Card Number *"
                    id="number"
                    values={formValues}
                    onChange={handleValueChange}
                    onFocus={() => handleValueChange('focus', 'number')}
                    error={notNull(formErrors?.number)}
                    helperText={formErrors?.number}
                  />
                  <MyInput
                    label="Card Holder Name *"
                    id="name"
                    values={formValues}
                    onChange={handleValueChange}
                    onFocus={() => handleValueChange('focus', 'name')}
                    error={notNull(formErrors?.name)}
                    helperText={formErrors?.name}
                  />
                  <MyInput
                    label="Expiration Date *"
                    id="expiry"
                    values={formValues}
                    onChange={handleValueChange}
                    onFocus={() => handleValueChange('focus', 'expiry')}
                    error={notNull(formErrors?.expiry)}
                    helperText={formErrors?.expiry}
                    maskProps={{
                      mask: [/\d/,/\d/,'/',/\d/,/\d/]
                    }}
                  />
                  <MyInput
                    label="CVC *"
                    id="cvc"
                    type="number"
                    values={formValues}
                    onChange={handleValueChange}
                    onFocus={() => handleValueChange('focus', 'cvc')}
                    error={notNull(formErrors?.cvc)}
                    helperText={formErrors?.cvc}
                    maxLength="4"
                  />
                  <MyButton
                    type="primary"
                    onClick={handleAddPayment}
                  >
                    <span className="btn-wrapper--label">Save</span>
                  </MyButton>
                </MyCardRow>
              </MyCardRow>
            </div>
          </div>
        </div>
      </DialogContentContainer>
      <DialogActionsContainer className="p-3">
        <Button
          variant="contained"
          className="btn-warning btn-transition-none"
          fullWidth
          onClick={handleModalClose}
        >
          <span className="btn-wrapper--icon">
            <ReplayTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Close</span>
        </Button>
      </DialogActionsContainer>
    </Dialog>
  );
};

export default AddCardModal;
