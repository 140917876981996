import styled from 'styled-components';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

export const DialogTitleContainer = styled(DialogTitle)`
  h2.MuiTypography-root {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 10px;
    }
  }

  @media (max-width: 576px) {
    button {
      display: none;
    }
  }
`;

export const DialogContentContainer = styled(DialogContent)`
  .dialog-content--wrapper {
    max-height: 520px;
    overflow-y: auto;

    .card-body {
      .card-body--column {
        max-width: 350px;

        &.w-100 {
          max-width: 100%;
        }
      }
    }

    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 10px;
    }

    .MuiOutlinedInput-root {
      border-radius: 0;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }

    .MuiOutlinedInput-multiline {
      padding: 0;
    }

    .MuiInputLabel-outlined {
      font-size: 14px;
      line-height: 14px;
      transform: translate(14px, 12px) scale(1);
      background: var(--reservation-book-section-background);
      padding: 0 3px;

      &.MuiInputLabel-shrink {
        transform: translate(14px, -5px) scale(0.75);
      }
    }

    .MuiOutlinedInput-input {
      padding: 10px;
      font-size: 14px;
      height: auto;
    }

    .MuiOutlinedInput-notchedOutline > legend {
      max-width: 0.01px;
    }

    &.card-dialog--wrapper {
      .MuiFormControl-root {
        margin-bottom: 0px;
      }
    }
  }
`;

export const DialogContentContainer1 = styled(DialogContent)`
`;

export const DialogActionsContainer = styled(DialogActions)`
  padding: 16px 24px !important;

  button {
    min-width: 150px;

    @media (max-width: 576px) {
      min-width: 0;
      width: 100%;
    }
  }
`;