import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';
import {
  Button,
  MenuItem,
  MenuList,
  Divider,
  Drawer,
  Menu,
  Paper,
  useMediaQuery
} from '@material-ui/core';

import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import ViewListTwoToneIcon from '@material-ui/icons/ViewListTwoTone';
import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { ReservationContext } from 'context/reservationContextContainer';
import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { reservationService, isLoggedIn, logout, getLoggedInUser, setLoggedInUser } from 'services/reservationService';
import { notNull, isNull, phoneFormat, creditCardType } from 'utils/text';

import ReservationHeader from 'components/ReservationHeader';
import LoginModal from 'components/ReservationModal/login';
import PassengerInfoModal from 'components/ReservationModal/info';
import AddCardModal from 'components/ReservationModal/card';
import { redirectTo } from '@reach/router';

const ReservationLayout = ({ children, pageName, baseToken, toolType = 'passenger' }) => {
  const {
    loginOpen,
    setLoginOpen,
    infoOpen,
    setInfoOpen,
    cardOpen,
    setCardOpen,
    menuOpen,
    setMenuOpen,
    baseSetting,
    setBaseSetting
  } = useContext(ReservationContext);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  useEffect(() => {
    async function initializeStep() {
      showCircularProgress('Loading...');
      const data = await reservationService.get_base_setting(baseToken);
      if (data && data?.data?.status === 'SUCCESS') {
        setBaseSetting({ ...data.data.data, baseToken });

        if (isLoggedIn(toolType)) {
          const data1 = await reservationService.login_token_check(toolType);
          if (data1 && data1?.data?.status === 'FAIL') {
            logout(baseToken, toolType);
          } else {
            setLoggedInUser({ ...getLoggedInUser(), ...data1.data.data }, toolType);
          }
        }
      }
      hideCircularProgress();
    }

    initializeStep();
  }, []);

  if (!baseSetting) {
    return null;
  }

  const gotoJobList = () => {
    navigate(`/reservation/list?token=${baseToken}&type=${toolType}`);
  }

  const gotoBookRide = () => {
    navigate(`/reservation?token=${baseToken}&type=${toolType}`);
  }

  const user = getLoggedInUser(toolType);

  return (
    <SnackbarProvider>
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <AppContainer>
        <div className="reservation-body-container" id="reservation_body_container">
          <ReservationHeader pageName={pageName} toolType={toolType} handleLoginOpen={() => setLoginOpen(true)} />
          <main>{children}</main>
          {isLoggedIn(toolType) && (
            <div className={`reservation-body--menu ${menuOpen ? 'menu-opened' : ''}`}>
              <div className="reservation-body--menu-list">
                <Button
                  className="reservation-body--menu-close"
                  onClick={() => setMenuOpen(false)}
                >
                  <CloseTwoToneIcon />
                </Button>
                <p className="reservation-body--menu-title">Welcome!</p>
                <div className="reservation-body--menu-info">
                  <span><PersonOutlineTwoToneIcon style={{ fontSize: 48 }} /> </span>
                  <div>
                    {toolType === 'account' && user?.account_name ? (
                      <span>{`${user.account_name}`}</span>
                    ) : toolType !== 'account' && user?.fname ? (
                      <span>{`${user?.fname} ${user?.lname}`}</span>
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        className="reservation-body--menu-name"
                        onClick={() => { setMenuOpen(false); setInfoOpen(true); }}
                      >
                        Unknown Name
                      </Button>
                    )}
                    <span>{toolType === 'account' ? user?.email : phoneFormat(user?.phone1)}</span>
                  </div>
                </div>
                <MenuList>
                  <Divider light />
                  <MenuItem onClick={() => { setMenuOpen(false); setCardOpen(true); }}>
                    <CreditCardTwoToneIcon fontSize="small" /> {toolType === 'account' ? user?.card_number : user?.token?.card_number ? `${creditCardType(user?.token?.card_number)}: ******${user?.token?.card_number.slice(-4)}` : 'No Card on File'}
                  </MenuItem>
                  <Divider light />
                  {toolType !== 'account' &&
                    <MenuItem>
                      <MonetizationOnIcon fontSize="small" /> Ride Points: {user?.loyal_point || 0}
                    </MenuItem>
                  }
                  <Divider light />
                  <MenuItem onClick={() => { setMenuOpen(false); gotoBookRide(); }}>
                    <AssignmentTurnedInTwoToneIcon fontSize="small" /> Book a Ride
                  </MenuItem>
                  <Divider light />
                  <MenuItem onClick={() => { setMenuOpen(false); gotoJobList(); }}>
                    <ViewListTwoToneIcon fontSize="small" /> Reservation List
                  </MenuItem>
                  <Divider light />
                  <MenuItem onClick={() => { setMenuOpen(false); logout(baseToken, toolType); }}>
                    <ExitToAppOutlinedIcon fontSize="small" /> Log Out
                  </MenuItem>
                </MenuList>
              </div>
              <div className="reservation-body--menu-backdrop" onClick={() => setMenuOpen(false)} />
            </div>
          )}
        </div>
        {loginOpen && (
          <LoginModal
            modalOpen={loginOpen}
            handleModalClose={() => setLoginOpen(false)}
            handleInfoOpen={() => setInfoOpen(true)}
            toolType={toolType}
          />
        )}
        {infoOpen && (
          <PassengerInfoModal
            modalOpen={infoOpen}
            handleModalClose={() => setInfoOpen(false)}
          />
        )}
        {cardOpen && (
          <AddCardModal
            modalOpen={cardOpen}
            handleModalClose={() => setCardOpen(false)}
          />
        )}
      </AppContainer>
    </SnackbarProvider>
    );
};

ReservationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReservationLayout;

const AppContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px;

  .reservation-body-container {
    border: 1px solid #efefef;
    background: #efefef;
    position: relative;
    overflow: hidden;

    .reservation-body--menu {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      pointer-events: none;

      .reservation-body--menu-list {
        background: #FFF;
        position: absolute;
        right: 0;
        height: 100%;
        width: 300px;
        max-width: calc(100% - 20px);
        z-index: 10000;
        transform: translate(100%, 0);
        visibility: hidden;
        transition: all 0.3s ease-in-out;
      }

      .reservation-body--menu-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #00000000;
        z-index: 9999;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
      }

      &.menu-opened {
        pointer-events: all;

        .reservation-body--menu-list {
          transform: translate(0, 0);
          box-shadow: -2px 0px 13px #000000aa;
          pointer-events: all;
          visibility: visible;
        }

        .reservation-body--menu-backdrop {
          background: #00000044;
          pointer-events: all;
        }
      }

      .reservation-body--menu-list {
        .reservation-body--menu-close {
          position: absolute;
          right: 10px;
          top: 28px;
        }

        .reservation-body--menu-title {
          font-size: 32px;
          font-weight: 900;
          text-align: left;
          padding: 24px 25px 10px;
          margin: 0;
        }

        .MuiMenuItem-root {
          padding: 16px 20px;
          font-size: 15px;

          .MuiSvgIcon-root {
            margin-right: 10px;
          }
        }

        .reservation-body--menu-info {
          display: flex;
          align-items: center;
          padding: 6px 16px;

          & > div {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            span {
              font-size: 14px;
            }
          }

          .reservation-body--menu-name {
            padding: 3px 0;
            margin-bottom: 3px;

            .MuiButton-label {
              font-size: 12px;
              line-height: 12px;
              padding: 0;
              font-style: italic;
            }
          }
        }
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .pac-target-input,
  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(14px, 12px) scale(1);
    background: var(--reservation-book-section-background);
    padding: 0 3px;

    &.MuiInputLabel-shrink {
      transform: translate(14px, -5px) scale(0.75);
    }
  }

  .MuiOutlinedInput-input {
    padding: 10px;
    font-size: 14px;
    height: auto;
  }

  .MuiOutlinedInput-notchedOutline > legend {
    max-width: 0.01px;
  }

  .MuiCheckbox-root {
    padding: 0 5px 0 9px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primary);
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: var(--primary);
  }

  .MuiFormControlLabel-root .MuiTypography-body1 {
    font-size: 14px;
  }

  .MuiFormControlLabel-root {
    min-width: 70px;

    &.error {
      .MuiRadio-colorSecondary.Mui-checked {
        color: red;
      }
      .MuiFormControlLabel-label {
        color: red;
      }
    }
  }

  .MuiTabs-root {
  }

  .MuiTabs-fixed {
    background: var(--blue-main-color);
  }

  .MuiTabs-indicator {
    display: none;
  }

  .form-tab-body {
    border: 2px solid var(--blue-main-color);
    border-top: none;
    padding: 25px 15px 10px;
  }

  .MuiTab-textColorPrimary {
    color: white;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: var(--blue-main-color);
    background: var(--reservation-book-section-background);
    border: 2px solid var(--blue-main-color);
    border-bottom: none;
  }

  .MuiButton-outlined,
  .MuiButton-contained {
    border-radius: 0;
    min-width: 130px;
  }

  .form-control-section {
    margin: 10px 0;
    .google-places-autocomplete {
      .google-places-autocomplete__suggestions-container {
        margin-top: -10px;
        background: var(--reservation-book-section-background);
        width: calc(100% - 2px);
        border: 1px solid var(--blue-main-color);
        cursor: pointer;
        .google-places-autocomplete__suggestion {
          font-size: 14px;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          border-bottom: 1px solid var(--blue-main-color);
        }
      }
    }
    .mapbox_autocomplete {
      position: relative;
      input {
        padding: 10px;
        font-size: 14px;
        height: auto;
        background: var(--reservation-book-section-background);
        margin-bottom: 10px;
        width: 100%;
        border: 1px solid lightgray;
        max-width: -webkit-fill-available;
      }
      input:focus {
        outline: 2px solid #3f51b5;
        box-sizing: inherit;
      }
      .react-geocoder-results {
        border: 1px solid var(--blue-main-color);
        margin-top: -10px;
        position: absolute;
        z-index: 3;
        background: var(--reservation-book-section-background);
        width: 100%;
        max-width: -webkit-fill-available;
        cursor: pointer;
        .react-geocoder-item {
          padding: 10px;
          font-size: 14px;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          border-bottom: 1px solid var(--blue-main-color);
          background: var(--reservation-book-section-background);
          width: 100%;
          max-width: -webkit-fill-available;
        }
      }
    }
  }

  .form-control-inline {
    display: flex;

    & > div {
      padding-left: 5px;
      padding-right: 5px;
      flex: 1;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
`;
