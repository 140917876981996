/* eslint-disable arrow-body-style */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import {
  Button,
  MenuItem,
  MenuList,
  Divider,
  Drawer,
  Menu,
  Paper,
  useMediaQuery
} from '@material-ui/core';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MenuIcon from '@material-ui/icons/Menu';

import { ReservationContext } from 'context/reservationContextContainer';

import { reservationService, isLoggedIn, logout, getLoggedInUser } from 'services/reservationService';
import { notNull, isNull, phoneFormat } from 'utils/text';

const ReservationHeader = ({ pageName, toolType, handleLoginOpen }) => {
  const { setMenuOpen } = useContext(ReservationContext);
  let pageTitle = '';
  switch (pageName) {
    case 'Book':
      pageTitle = 'Book a Ride';
      break;
    case 'JobList':
      pageTitle = 'Reservation List';
      break;
    default:
      pageTitle = 'Book a Ride';
      break;
  }

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  return (
    <ReservationHeaderContainer>
      <Paper
        className="reservation-header--wrapper"
        elevation={1}
        square
      >
        <div className="reservation-header--content">
          <div className="reservation-header--left">
            <span>{pageTitle}{`${toolType === 'account' ? ' (Account)' : ''}`}</span>
          </div>
          <div className="reservation-header--right">
            {isLoggedIn(toolType) ? (
              <Button
                className="header--menu-button"
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </Button>
            ) : (
              <Button
                size="medium"
                variant="outlined"
                className="reservation-header--login-btn btn-transition-none"
                onClick={handleLoginOpen}
              >
                <span className="btn-wrapper--icon">
                  <PersonOutlineIcon fontSize="small" />
                </span>
                <span className="btn-wrapper--label">Login</span>
              </Button>
            )}
          </div>
        </div>
      </Paper>
    </ReservationHeaderContainer>
  )
};

export default ReservationHeader;

const ReservationHeaderContainer = styled.div`
  font-family: 'Roboto';

  .reservation-header--wrapper {
    height: 64px;
  }

  .reservation-header--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    height: 100%;

    .reservation-header--left {
      span {
        font-size: 24px;
        font-weight: 900;
        color: #354f5b;
        text-transform: uppercase;
      }
    }
  }
`;