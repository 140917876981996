import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import {
  Button,
  Dialog,
  Avatar,
} from '@material-ui/core';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';

import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import { CircularProgressContext } from 'components/CircularProgressOverlay';

import { notNull, isNull, cleanPhone } from 'utils/text';
import { reservationService, isLoggedIn, setLoggedInUser } from 'services/reservationService';

import { DialogTitleContainer, DialogContentContainer, DialogActionsContainer } from './style';

const PassengerInfoModal = ({ modalOpen, handleModalClose }) => {
  const nameType = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
  };
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [formValid, setFormValid] = useState({
    firstName: 0,
    lastName: 0,
    email: 0,
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isUpdate, setUpdate] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  const handleChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value
    });
  }

  const checkFieldValidate = (id, value) => {
    const fieldValidation = formValid;
    const fieldValidationErrors = formErrors;

    let valid = value && value.length > 0;
    if (id === 'email') {
      valid = !valid || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      fieldValidation[id] = valid ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : `${nameType[id]} is not valid`;
    } else {
      fieldValidation[id] = valid ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : `${nameType[id]} is required`;
    }

    setFormValid(fieldValidation);
    setFormErrors(fieldValidationErrors);
    setUpdate(!isUpdate);
    return fieldValidation[id];
  };

  const checkStepValidate = () => {
    let stepValid = 0;
    const stepFields = ['firstName', 'lastName', 'email'];

    stepFields.forEach(element => {
      stepValid += checkFieldValidate(element, formValues[element]);
    });

    return stepValid === stepFields.length;
  };

  const handleSubmit = async () => {
    if (!checkStepValidate()) {
      return;
    }

    showCircularProgress('Please wait');
    const data = await reservationService.passenger_info_update({
      fname: formValues.firstName,
      lname: formValues.lastName,
      email: formValues.email,
    });

    if (data && data?.data?.status === 'SUCCESS') {
      const userData = data.data.data;
      setLoggedInUser({ ...userData });
      handleModalClose();
    }
    hideCircularProgress();
  }

  return (
    <Dialog
      maxWidth="md"
      open={modalOpen && isLoggedIn()}
      onClose={handleModalClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer>
        <PersonOutlineIcon /><span>Personal Information</span>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column">
              <PergoTextField
                id="firstName"
                label="First Name"
                values={formValues}
                handleChange={handleChange}
                formValid={formValid}
                formErrors={formErrors}
              />
              <PergoTextField
                id="lastName"
                label="Last Name"
                values={formValues}
                handleChange={handleChange}
                formValid={formValid}
                formErrors={formErrors}
              />
              <PergoTextField
                id="email"
                label="Email"
                values={formValues}
                handleChange={handleChange}
                formValid={formValid}
                formErrors={formErrors}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="btn-transition-none"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </DialogContentContainer>
      <DialogActionsContainer className="p-3">
        <Button
          variant="contained"
          className="btn-warning btn-transition-none"
          fullWidth
          onClick={handleModalClose}
        >
          <span className="btn-wrapper--icon">
            <ReplayTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Close</span>
        </Button>
      </DialogActionsContainer>
    </Dialog>
  );
}

export default PassengerInfoModal;