import React, { useState } from 'react';

const ReservationContext = React.createContext(null);
export { ReservationContext };

const ReservationContextContainer = props => {
  const [baseSetting, setBaseSetting] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  
  return (
    <ReservationContext.Provider
      value={{
        baseSetting,
        setBaseSetting,
        loginOpen,
        setLoginOpen,
        infoOpen,
        setInfoOpen,
        cardOpen,
        setCardOpen,
        menuOpen,
        setMenuOpen,
      }}
    >
      {props.children}
    </ReservationContext.Provider>
  );
};

export default ReservationContextContainer;
