import React, { useEffect, useState, useContext } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';

import ReservationLayout from 'layouts/reservationLayout';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import NotificationOverlay from 'components/NotificationOverlay';

import ReservationContextContainer from 'context/reservationContextContainer';

const ReservationTemplate = props => {
  const { pageName } = props.pageContext;
  const parsed = queryString.parse(props.location.search);
  if (!parsed?.token) {
    return null;
  }

  let PageContent = null;

  switch (pageName) {
    case 'Book':
      PageContent = loadable(() => import('./Book'));
      break;
    case 'JobList':
      PageContent = loadable(() => import('./JobList'));
      break;
    default:
      PageContent = loadable(() => import('./Book'));
      break;
  }

  if (!PageContent) {
    return null;
  }

  return (
    <ReservationContextContainer>
      <CircularProgressOverlay>
        <NotificationOverlay>
          <ReservationLayout pageName={pageName} baseToken={parsed.token} toolType={parsed.type || 'passenger'}>
            <PageContent {...props} toolType={parsed.type || 'passenger'} />
          </ReservationLayout>
        </NotificationOverlay>
      </CircularProgressOverlay>
    </ReservationContextContainer>
  );
};

export default ReservationTemplate;
