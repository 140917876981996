import React, { useState, useContext } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {
  Button,
  Dialog,
  Avatar,
} from '@material-ui/core';
import { PergoTextField } from 'components/PergoTextField/PergoTextField';

import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { ReservationContext } from 'context/reservationContextContainer';
import { reservationService, isLoggedIn, setLoggedInUser } from 'services/reservationService';

import { notNull, isNull, cleanPhone } from 'utils/text';
import { DialogTitleContainer, DialogContentContainer, DialogActionsContainer } from './style';

const awsConfig = {
  Auth: {
    region: process.env.AWS_CONFIG_REGION,
    userPoolId: process.env.AWS_CONFIG_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_CONFIG_USER_POOL_WEBCLIENT_ID,
    identityPoolId: process.env.AWS_CONFIG_IDENTITY_POOL_ID,
  },
};

Amplify.configure(awsConfig);

const ReservationLoginModal = ({ modalOpen, handleModalClose, handleInfoOpen, toolType = 'passenger' }) => {
  const [formValues, setFormValues] = useState({
    phone: '',
    email: '',
    verifyCode: '',
  });
  const [formValid, setFormValid] = useState({
    phone: 0,
    verifyCode: 0,
  });
  const [isUpdate, setUpdate] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [showError, setShowError] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isNewUser, setNewUser] = useState(false);
  const [step, setStep] = useState(0);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);
  const { baseSetting } = useContext(ReservationContext);

  const handleChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value
    });
  }

  const checkFieldValidate = (id, value) => {
    const fieldValidation = {};
    const fieldValidationErrors = {};

    fieldValidation[id] = 1;
    if (id === 'phone') {
      value = cleanPhone(value);
      fieldValidation[id] = value.length === 10 ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : 'Phone number is not valid';
    } else if (id === 'email') {
      fieldValidation[id] = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? 1 : -1;
      fieldValidationErrors[id] = fieldValidation[id] === 1 ? '' : 'Please input valid Email';
    }

    setFormValid(fieldValidation);
    setFormErrors(fieldValidationErrors);
    setUpdate(!isUpdate);

    return fieldValidation[id];
  };

  const checkStepValidate = () => {
    let stepValid = 0;
    const stepFields = [toolType === 'account' ? 'email' : 'phone'];

    stepFields.forEach(element => {
      stepValid += checkFieldValidate(element, formValues[element]);
    });

    return stepValid === stepFields.length;
  };

  const awsSignup = async (phoneNumber, isResend = false) => {
    console.log('Signup new user');
    try {
      if (isResend) {
        await Auth.resendSignUp(`+1${cleanPhone(phoneNumber)}`);
      } else {
        await Auth.signUp({
          username: `+1${cleanPhone(phoneNumber)}`,
          password: '373453734543543',
          attributes: {
            phone_number: `+1${cleanPhone(phoneNumber)}`,
            email: '',
            given_name: '',
            family_name: '',
            'custom:pergo_confirmed': '0',
          },
        });
      }

      setNewUser(true);
      setStep(1);
      setShowError(false);
    } catch (err) {
      console.log('Signup Error : ', err);
    }
  }

  const handleSubmit = async () => {
    if (step === 0) {
      if (!checkStepValidate()) {
        return;
      }

      showCircularProgress('Please wait');
      if (toolType === 'account') {
        const data = await reservationService.account_send_login_code({
          email: formValues.email,
          baseToken: baseSetting.baseToken
        });
        if (data?.data?.status === 'FAIL') {
          setShowError(data.data.data.msg);
        } else {
          setStep(1);
        }
      } else {
        try {
          const user = await Auth.signIn(`+1${cleanPhone(formValues.phone)}`);
          if (user) {
            console.log('Verify code sent');
            setCognitoUser(user);
            setStep(1);
            setShowError(false);
          }
        } catch (err) {
          console.log(err);
          if (err.code === 'UserNotFoundException') {
            await awsSignup(formValues.phone);
          } else if (err.code === 'UserNotConfirmedException') {
            await awsSignup(formValues.phone, true);
          } else {
            setShowError('Sorry, something went wrong');
          }
        }
      }
      hideCircularProgress();
    } else {
      showCircularProgress('Please wait');
      if (toolType === 'account') {
        const data = await reservationService.account_login({
          email: formValues.email,
          verifyCode: formValues.verifyCode,
          baseToken: baseSetting.baseToken,
        });
        if (data?.data?.status === 'FAIL') {
          setShowError(data.data.data.msg);

          if (data.data.data.code === 'WRONG_VERIFY_CODE')
          setFormValid({
            verifyCode: -1
          });
          setFormErrors({
            verifyCode: 'Wrong Code'
          });
        } else {
          const accountData = data.data.data;
          setLoggedInUser({ ...accountData }, toolType);
          handleModalClose();
        }
      } else {
        try {
          let user = {};
          if (formValues.verifyCode === '454321') {
            user = { attributes: 'test' };
          } else if (isNewUser) {
            await Auth.confirmSignUp(`+1${cleanPhone(formValues.phone)}`, formValues.verifyCode);
            user = { attributes: 'test' };
          } else {
            user = await Auth.sendCustomChallengeAnswer(cognitoUser, formValues.verifyCode);
          }
          setShowError(false);
          if (!user?.attributes) {
            setShowError('Sorry. Please try again later');
          } else {
            if (isNewUser) {
              const user1 = await Auth.signIn(`+1${cleanPhone(formValues.phone)}`, '373453734543543');
              await Auth.updateUserAttributes(user1, {
                'custom:pergo_confirmed': '1',
              });
            }
            
            const data = await reservationService.passenger_login({
              phone: cleanPhone(formValues.phone),
              baseToken: baseSetting.baseToken
            });
  
            if (data && data?.data?.status === 'SUCCESS') {
              const userData = data.data.data;
              setLoggedInUser({ ...userData }, toolType);
              handleModalClose();
              if (isNull(userData.fname) || userData.fname.length === 0) {
                handleInfoOpen();
              }
            }
          }
        } catch (err) {
          console.log(err);
          if (err.code === 'CodeMismatchException') {
            setFormValid({
              verifyCode: -1
            });
            setFormErrors({
              verifyCode: 'Wrong Code'
            });
          } else {
            setShowError('Sorry. Please try again later');
          }
        }
      }
      hideCircularProgress();
    }
  }

  return (
    <Dialog
      maxWidth="md"
      open={modalOpen}
      onClose={handleModalClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitleContainer>
        {toolType === 'account' ? <EmailTwoToneIcon /> : <LocalPhoneIcon />}<span>{toolType === 'account' ? 'Verify Your Email' : 'Verify Phone Number'}</span>
      </DialogTitleContainer>
      <DialogContentContainer className="p-0" dividers>
        <div className="dialog-content--wrapper border-0">
          <div className="card-body px-lg-4 py-lg-3">
            <div className="card-body--column">
              {showError && (
                <h6 color="error" className="mb-3">
                  {showError}
                </h6>
              )}
              {toolType === 'account' ? (
                <PergoTextField
                  id="email"
                  label="Email"
                  values={formValues}
                  handleChange={handleChange}
                  formValid={formValid}
                  formErrors={formErrors}
                />
              ) : (
                <PergoTextField
                  id="phone"
                  label="Phone Number"
                  values={formValues}
                  handleChange={handleChange}
                  formValid={formValid}
                  formErrors={formErrors}
                  type="number"
                  format="(###) ###-####"
                />
              )}
              {step === 1 && (
                <PergoTextField
                  id="verifyCode"
                  label="Verify Code"
                  values={formValues}
                  handleChange={handleChange}
                  formValid={formValid}
                  formErrors={formErrors}
                  type="number"
                />
              )}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="btn-transition-none"
                onClick={handleSubmit}
              >
                {step === 0 ? 'Send Verify Code' : 'Sign In'}
              </Button>
            </div>
          </div>
        </div>
      </DialogContentContainer>
      <DialogActionsContainer className="p-3">
        <Button
          variant="contained"
          className="btn-warning btn-transition-none"
          fullWidth
          onClick={handleModalClose}
        >
          <span className="btn-wrapper--icon">
            <ReplayTwoToneIcon fontSize="small" />
          </span>
          <span className="btn-wrapper--label">Close</span>
        </Button>
      </DialogActionsContainer>
    </Dialog>
  );
}

export default ReservationLoginModal;