/* eslint-disable import/prefer-default-export */
import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import './PergoTextField.less';

export const PergoTextField = props => {
  const {
    id,
    type = 'text',
    format,
    label,
    readonly,
    values,
    formValid,
    formErrors,
    handleFocus,
    handleBlur,
    handleChange,
    handleKeyPress,
    ...otherProps
  } = props;

  const onHandleFocus = () => {
    if (handleFocus) {
      handleFocus(id);
    }
  };

  const onHandleBlur = event => {
    if (handleBlur) {
      handleBlur(id, event.target.value);
    }
  };

  const onHandleChange = event => {
    if (handleChange) {
      handleChange(id, event.target.value);
    }
  };

  const onHandleKeyPress = event => {
    if (handleKeyPress) {
      handleKeyPress(event);
    }
  };

  return (
    <>
      {(type === 'text' || type === 'password') && (
        <TextField
          label={label}
          variant="outlined"
          InputProps={readonly === true ? { readOnly: true } : {}}
          onBlur={onHandleBlur}
          onChange={onHandleChange}
          onFocus={onHandleFocus}
          onKeyPress={onHandleKeyPress}
          type={type}
          {...(values && { value: values[id] || '' })}
          {...(formValid && { error: formValid[id] === -1 })}
          {...(formErrors && formErrors[id] && formErrors[id] !== '' && { label: formErrors[id] })}
          {...otherProps}
        />
      )}
      {(type === 'number' || type === 'currency') && (
        <NumberFormat
          format={
            type === 'number'
              ? format
              : value => parseFloat(value) <= 0
                    ? ''
                    : `$ ${ 
                        parseFloat(value / 100)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          }
          label={label}
          variant="outlined"
          customInput={TextField}
          InputProps={readonly === true ? { readOnly: true } : {}}
          onBlur={onHandleBlur}
          onChange={onHandleChange}
          onFocus={onHandleFocus}
          onKeyPress={onHandleKeyPress}
          {...(values && { value: values[id] })}
          {...(formValid && { error: formValid[id] === -1 })}
          {...(formErrors && formErrors[id] && formErrors[id] !== '' && { label: formErrors[id] })}
          {...otherProps}
        />
      )}
    </>
  );
};
